import { Deal } from '../const';
export const hotelTopAccent = (hotel, translations) => {
  const topOffer = {
    offerId: null,
    offer: null,
  };
  let offer;
  if (hotel.deals_data && Object.keys(hotel.deals_data).length) {
    offer = Object.values(hotel.deals_data)
      .filter(deal => deal.deal_type === Deal.DiscountType.DISCOUNT_PERCENT)
      .sort((a, b) => b.deal_amount - a.deal_amount)?.[0];
    if (offer) {
      // use data from the offer
      topOffer.offerId = offer.id;
      topOffer.offer = {
        title: offer.overview + ' ' + offer.accent,
        description: offer.overview + ' ' + offer.accent + ', ' + offer.period_stay + ', ' + offer.payment_conditions,
        overview: offer.overview,
        accent: offer.accent,
        morelink: offer.morelink,
      };
    }
  }
  if (!offer) {
    let overview = '';
    if (hotel.standalone_initiative && hotel.standalone_initiative.includes('eb')) {
      overview = translations?.EarlyBookingDiscount;
    } else if (hotel.standalone_initiative && hotel.standalone_initiative.includes('lm')) {
      overview = translations?.VarLastMinute;
    }
    const accent = ' -' + (hotel.standalone_initiative_data ?? 10) + '%';
    if (overview)
      topOffer.offer = {
        title: overview + accent,
        description: '',
        overview,
        accent,
        morelink: '',
      };
  }
  return topOffer;
};
