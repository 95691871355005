import { useState, useEffect } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import { uppercaseFirstLetter, lowercaseFirstLetter, singleOrPlural } from '../../../../utils';
import { usePersonalDataStore } from '../../../../store/personalDataStore/usePersonalDataStore';
import useFetchHotelSelective from '../../../../features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from '../../../../features/translations/hooks/useFetchTranslationsSelective';

const AccommodationPax = ({
  accommodationData,
  index,
  isLast,
  setNumberOfAdults,
  setNumberOfChildren,
  setChildAge,
  setAccommodationType,
  removeAccommodation,
  displayLabels = false,
}) => {
  const personalData = usePersonalDataStore(state => state.personalData);
  const translationWords = [
    'VarAdults',
    'VarChildren',
    'VarAdult',
    'VarChild',
    'VarRoom',
    'VarAge',
    'VarYearsOld',
    'NumberAdults',
    'NumberChildren',
    'FormRoomType',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
  const hotelFields = ['price_types_data'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);

  const adultOptions = [...Array(4)].map((x, i) => ({
    label: i + 1 + ' ' + lowercaseFirstLetter(singleOrPlural(translations?.VarAdult, translations?.VarAdults, i + 1)),
    value: i + 1,
  }));

  const childOptions = [...Array(5)].map((x, i) => ({
    label: i + ' ' + lowercaseFirstLetter(singleOrPlural(translations?.VarChild, translations?.VarChildren, i)),
    value: i,
  }));

  const childAges = [...Array(18)].map((x, i) => ({
    label: i + ' ' + lowercaseFirstLetter(translations?.VarYearsOld),
    value: i,
  }));

  useEffect(() => {
    if (hotel?.price_types_data && accommodationData?.accommodationType) {
      // if selected accommodationData is not available for booking, then unset it
      const priceTypesIds = Object.values(hotel.price_types_data).map(pricetypeOption => {
        return pricetypeOption.position.toString();
      });

      if (priceTypesIds?.length && !priceTypesIds.includes(accommodationData?.accommodationType?.toString())) {
        setAccommodationType('', index);
      }
    }
  }, [hotel, personalData?.myCountryCode]);

  if (translationsStatus !== 'success') return <></>;

  return (
    <div className="form-rooms-room">
      <span>
        {translations ? uppercaseFirstLetter(translations.VarRoom) : ''} {index + 1}
        {index && isLast ? <FaWindowClose onClick={removeAccommodation} /> : ''}
      </span>
      {setAccommodationType && hotel?.price_types_data ? (
        <div className="some-class-here">
          <div className="form-outer">
            {displayLabels ? (
              <label htmlFor="adultlist">{uppercaseFirstLetter(translations?.FormRoomType)}:</label>
            ) : (
              ''
            )}
            <select
              id="rooms"
              name="roomslist"
              form="roomsform"
              defaultValue={accommodationData?.accommodationType}
              onChange={e => {
                setAccommodationType(e.target.value, index);
              }}
            >
              <option value="">{uppercaseFirstLetter(translations?.FormRoomType)}</option>
              {Object.values(hotel.price_types_data)
                .sort((room1, room2) => {
                  return room1?.max_adults === 1 ? 1 : -1;
                })
                .map(pricetypeOption => (
                  <option value={pricetypeOption.position}>{pricetypeOption.caption}</option>
                ))}
            </select>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="form-rooms-room-top">
        <div className="form-outer">
          {displayLabels ? <label htmlFor="adultlist">{uppercaseFirstLetter(translations?.NumberAdults)}:</label> : ''}
          <select
            id="adult"
            className="form-adults"
            name="adultlist"
            form="adultform"
            defaultValue={accommodationData?.numberOfAdults}
            onChange={e => {
              setNumberOfAdults(e.target.value, index);
            }}
          >
            <option value="" disabled>
              {translations?.VarAdults}
            </option>
            {adultOptions.map(adultOption => (
              <option value={adultOption.value}>{adultOption.label}</option>
            ))}
          </select>
        </div>
        <div className="form-outer">
          {displayLabels ? (
            <label htmlFor="childlist">{uppercaseFirstLetter(translations?.NumberChildren)}:</label>
          ) : (
            ''
          )}

          <select
            id="child"
            className="form-childs"
            name="childlist"
            form="childform"
            defaultValue={accommodationData?.numberOfChildren}
            onChange={e => {
              setNumberOfChildren(e.target.value, index);
            }}
          >
            <option value="" disabled>
              {translations?.VarChildren}
            </option>
            {childOptions.map(childOption => (
              <option value={childOption.value}>{childOption.label}</option>
            ))}
          </select>
        </div>
      </div>
      {accommodationData?.numberOfChildren ? (
        <div className="children-age">
          <div className="children-age-select">
            {[...Array(parseInt(accommodationData.numberOfChildren)).keys()].map((x, i) => (
              <div className="child-age-select">
                <label htmlFor={`child$(i)`}>
                  {uppercaseFirstLetter(translations?.VarChild)} {i + 1}:
                </label>
                <select
                  id={`child$(i)`}
                  name="childrenlist"
                  form="adultform"
                  defaultValue={accommodationData?.childrenAges?.[i]}
                  onChange={e => {
                    setChildAge(e.target.value, index, i);
                  }}
                >
                  <option value="" disabled>
                    {translations?.VarAge} - {lowercaseFirstLetter(translations?.VarChild)} {i + 1}
                  </option>
                  {childAges.map(childAge => (
                    <option value={childAge.value}>{childAge.label}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AccommodationPax;
