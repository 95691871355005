import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './App.css';
//import "./public/css/sttle.css";

import Footer from './components/layout/Footer/Footer.jsx';
import Router from './routers/Router';

import ErrorBoundaryComponent from './components/common/ErrorBoundaryComponent/ErrorBoundaryComponent';

import { useLanguageStore } from './store/languageStore/useLanguageStore';
export const queryClient = new QueryClient();

const App = () => {
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundaryComponent>
        <div className="App">
          <Router />
          <Footer />
        </div>
      </ErrorBoundaryComponent>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
