import { SkiServices, Travel } from '../../const';
import { upperAgeLimit } from '../upperAgeLimit';

export const skiServiceName = (skiServicesData, skiServiceType, skiServiceId, shortMode = false) => {
  if (!skiServicesData) return '';

  if (+skiServiceType === +SkiServices.Group.SNOWBOARD) {
    if (skiServicesData?.snowboard_service_names[+skiServiceId]) {
      if (shortMode) return skiServicesData?.snowboard_service_short[+skiServiceId];
      else return skiServicesData?.snowboard_service_names[+skiServiceId];
    }
  }
  if (shortMode) return skiServicesData?.ski_service_short[+skiServiceId];
  else return skiServicesData?.ski_service_names[+skiServiceId];
};
