import { useQuery } from 'react-query';
import { endPoints, reactQuerySlowChangingConfig } from '../../../api/api.config';
import { getAllApiLang } from '../../../api/api.calls';
import { HtmlPageModel } from '../htmlpage';
import { useLanguageStore } from '../../../store/languageStore/useLanguageStore';

/*This function won't send an http request if not necessary.
 * So we can use this function to sync states in different components
 * */
export default function useFetchHtmlPage(filename: string) {
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  const r = useQuery(
    'htmlpage-' + filename + '-' + activeLanguage,
    () => GetHtmlPage(filename, activeLanguage),
    reactQuerySlowChangingConfig,
  );

  return r;
}

/**
 * Helper function that returns response.data only
 * @returns something
 */
async function GetHtmlPage(filename: string, language: string) {
  const promise = getAllApiLang<HtmlPageModel[]>(endPoints.htmlPages, language, { filename });

  const dataPromise = promise.then(response => response.data?.[filename]);

  return dataPromise;
}
