import { useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import '../Loaders.scss';

const MainLoader = ({ height = 150 }) => {
  return (
    <div className="loader-container main-loader-container">
      <ThreeCircles
        height={height}
        width="100%"
        color="#F56960"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="#181A2F"
        innerCircleColor="#EFEFEF"
        middleCircleColor="#F56960"
      />
    </div>
  );
};

export default MainLoader;
