import './SearchFormLayoutSide.scss';

const SearchFormLayoutSide = ({
  datePickerRange,
  accommodationsList,
  addAccommodationsLink,
  formTitle,
  buttonText,
  onFormSubmit,
}) => {
  return (
    <div className="widget-bg booking-form-wrap booking-form-short">
      {formTitle ? <h3>{formTitle}</h3> : ''}
      <form className="booking-form" onSubmit={onFormSubmit}>
        <div className="booking-form-section">
          <div className="row">
            {datePickerRange}
            <div className="form-adults-childredn">
              <div className="row">
                <div className=" col-12 form-rooms">
                  {accommodationsList}
                  {addAccommodationsLink}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="booking-form-section">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group submit-btn">
                <input type="submit" name="submit" value={buttonText} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchFormLayoutSide;
