//import "./Footer.scss";
import { useLanguageStore } from '../../../store/languageStore/useLanguageStore';
import useFetchHtmlPage from '../../../features/htmlpages/hooks/useFetchHtmlPage';
import useFetchHotelSelective from '../../../features/hotels/hooks/useFetchHotelSelective';
import touroperatorLicense from '../../../assets/images/touroperator_license.gif';
import iataLogo from '../../../assets/images/iata.png';
import paymentOptions from '../../../assets/images/payments2.png';

import './Footer.scss';

const Footer = () => {
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  const { data: footetLinksB2B, status: htmlPageStatus1 } = useFetchHtmlPage('footer-links-new-b2b');
  const { data: footetLinksGeneral, status: htmlPageStatus2 } = useFetchHtmlPage('footer-links-new-general');

  const hotelFields = ['address_full', 'address_full_bg'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);

  return (
    <footer id="colophon" className="site-footer footer-primary">
      <div className="top-footer">
        <div className="container">
          <div className="row footer-top">
            <div className="col-md-12">
              <div className="footer-menu fm2">
                <ul dangerouslySetInnerHTML={{ __html: footetLinksB2B?.html }} />
              </div>
            </div>
            <br />
            <br />
            <br />

            <div className="col-md-4 col-sm-6 footer-colums">
              <aside className="widget widget_text">
                <h3 className="widget-title">About US</h3>
                <div className="textwidget widget-text about-p">
                  <p>
                    <img src={touroperatorLicense} alt="" />
                    <span>Touroperator license #03475</span>
                    <br />
                  </p>
                  <p>
                    <img src={iataLogo} alt="" />
                    <span>We are member of IATA</span>
                  </p>
                </div>
              </aside>
            </div>
            <div className="col-md-4 col-sm-6 footer-colums">
              <aside className="widget widget_text">
                <h3 className="widget-title">CONTACT INFORMATION</h3>
                <div className="textwidget widget-text">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fas fa-phone-alt"></i> 0888 398 270
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-envelope"></i>booking@lionbansko.com
                      </a>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>{' '}
                      {hotel?.address_full ? (
                        <>{activeLanguage === 'bg' ? hotel?.address_full_bg : hotel?.address_full}</>
                      ) : (
                        ''
                      )}
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="col-md-3 col-sm-12 footer-colums">
              <aside className="widget widget_recent_post">
                <h3 className="widget-title">Online Payments with</h3>
                <ul>
                  <li>
                    <div className="entry-meta">
                      <span className="post-on">
                        <img src={paymentOptions} alt="" />
                      </span>
                    </div>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <div className="buttom-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="footer-menu" style={{ paddingBottom: '0' }}>
                <ul dangerouslySetInnerHTML={{ __html: footetLinksGeneral?.html }} />
              </div>
            </div>

            <div className="col-md-5">
              <div className="copy-right text-right">Copyright © 2022 Profi Tours Ltd. All rights reserved</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
