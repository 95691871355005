import { SkiServices, Travel } from '../../const';
import { upperAgeLimit } from '../upperAgeLimit';

export const skiAgeRangeText = (agesData, age, skiServiceType, skiServiceId, translations) => {
  console.log('skiAgeRangeText params: ', agesData, translations);
  if (!(agesData && translations)) return '';
  if (+skiServiceType === +SkiServices.Group.KINDERGARTEN) {
    if (+skiServiceId === +SkiServices.Type.NURSERY_NON_SKI) {
      return (
        agesData?.['nursery_min_age'] +
        ' - ' +
        upperAgeLimit(agesData?.['nursery_max_age']) +
        ' ' +
        translations?.VarYearsOldAbbrev
      );
    } else {
      return (
        agesData?.['kindergarten_min_age'] +
        ' - ' +
        upperAgeLimit(agesData?.['kindergarten_max_age']) +
        ' ' +
        translations?.VarYearsOldAbbrev
      );
    }
  } else {
    let childrenMinAge;
    let childrenMaxAge;

    if (+skiServiceType === +SkiServices.Group.SNOWBOARD) {
      childrenMinAge = agesData?.['children_min_age_snowboard'];
      childrenMaxAge = agesData?.['children_max_age_snowboard'];
    } else {
      childrenMinAge = agesData?.['children_min_age'];
      childrenMaxAge = agesData?.['children_max_age'];
    }

    if (age === +Travel.Age.ADULT) {
      let minAge = childrenMaxAge;
      if (agesData?.['youth_max_age'] > 0) minAge = agesData?.['youth_max_age'];

      if (false && agesData?.['seniors_min_age'])
        return (
          ' (' +
          minAge +
          ' - ' +
          upperAgeLimit(agesData?.['seniors_min_age']) +
          ' ' +
          translations?.VarYearsOldAbbrev +
          ')'
        );
      else return ' (' + minAge + '+ ' + translations?.VarYearsOldAbbrev + ')';
    } else if (age === +Travel.Age.SENIOR) {
      return ' (' + agesData?.['seniors_min_age'] + '+ ' + translations?.VarYearsOldAbbrev + ')';
    } else if (age === +Travel.Age.YOUTH) {
      return (
        ' (' +
        agesData?.['children_max_age'] +
        ' - ' +
        upperAgeLimit(agesData?.['youth_max_age']) +
        ' ' +
        translations?.VarYearsOldAbbrev +
        ')'
      );
    } else if (age === +Travel.Age.CHILD) {
      return (
        ' (' + childrenMinAge + ' - ' + upperAgeLimit(childrenMaxAge) + ' ' + translations?.VarYearsOldAbbrev + ')'
      );
    }
  }

  return '';
};
