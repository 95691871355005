export const localizedPathNames = translations => {
  return {
    home: '/',
    hotel: `/${encodeURIComponent(translations?.HotelUrl)}.html`,
    facilities: `/${encodeURIComponent(translations?.UrlModeKeyFacts)}.html`,
    rooms: `/${encodeURIComponent(translations?.UrlModeRooms)}.html`,
    booking: `/${encodeURIComponent(translations?.UrlModeBooking)}.html`,
    gallery: `/${encodeURIComponent(translations?.UrlModePhotos)}.html`,
    location: `/${encodeURIComponent(translations?.UrlModeAddress)}.html`,
    prices: `/${encodeURIComponent(translations?.UrlModePricesDiscounts)}.html`,
    transfers: `/${encodeURIComponent(translations?.VillageUrl)}/${encodeURIComponent(
      translations?.VillageUrl,
    )}-${encodeURIComponent(translations?.UrlModeTransfers)}.html`,
    transferBooking: `/${encodeURIComponent(translations?.VillageUrl)}/${encodeURIComponent(
      translations?.VillageUrl,
    )}-${encodeURIComponent(translations?.UrlModeTransfers)}-${encodeURIComponent(translations?.UrlModeBooking)}.html`,
    skipacks: `/${encodeURIComponent(translations?.VillageUrl)}/${encodeURIComponent(
      translations?.VillageUrl,
    )}-${encodeURIComponent(translations?.UrlModeSkiPackages)}/`,
    skipacksBooking: `/${encodeURIComponent(translations?.VillageUrl)}/${encodeURIComponent(
      translations?.VillageUrl,
    )}-${encodeURIComponent(translations?.UrlModeSkiPackages)}/${encodeURIComponent(
      translations?.UrlModeBooking,
    )}.html`,
    faq: '/p/faq.html',
    translations: '/translations',
    errorNotFound: '/error404',
    errorNoData: '/errornodata',
    room: `/${encodeURIComponent(translations?.UrlModeRooms)}/:roomUrl.html`,
    test: '/test.html',
    dev1: '/dev1.html',
    searchResults: `/${encodeURIComponent(translations?.VarSearch)}.html`,
    htmlPageWithLangFiledirParentdir: '/:lang/:parentdir/:filedir/:pageUrl.html',
    htmlPageWithLangFiledir: '/:lang/:filedir/:pageUrl.html',
    htmlPageWithLang: '/:lang/:pageUrl.html',
    htmlPage: '/:pageUrl.html',
    dev2: '/dev2',
    dev3: '/dev3',
    dev4: '/dev4',
    dev5: '/dev5',
    dev6: '/dev6',
  };
};
