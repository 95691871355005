import { Bars } from 'react-loader-spinner';
import '../Loaders.scss';

const HorizontalLoader = ({ height = 40 }) => {
  return (
    <div className="loader-container">
      <Bars
        height={height}
        width="100%"
        color="#181A2F"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        padding="20"
      />
    </div>
  );
};

export default HorizontalLoader;
