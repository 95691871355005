import { useQuery } from 'react-query';
import { endPoints, reactQuerySlowChangingConfig } from '../../../api/api.config';
import { getAllApi } from '../../../api/api.calls';
import { LocationModel } from '../location';

export default function useGetGeoLocation() {
  const r = useQuery('geolocation', () => GetGeoLocation(), reactQuerySlowChangingConfig);

  return r;
}

/**
 * Helper function that returns response.data only
 * @returns something
 */
async function GetGeoLocation() {
  const promise = getAllApi<LocationModel>(endPoints.location);

  const dataPromise = promise.then(response => response?.data);

  return dataPromise;
}
