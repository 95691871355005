import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponent from '../ErrorFallbackComponent/ErrorFallbackComponent';

const ErrorBoundaryComponent = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallbackComponent}
      onError={(error, errorInfo) => console.log('ErrorFallback: ', { error, errorInfo })}
      onReset={() => {
        // reset the state of your app
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryComponent;
