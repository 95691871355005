import { BrowserRouter } from 'react-router-dom';
import { usePathsStore } from '../store/pathsStore/usePathsStore';
import Header from '../components/layout/Header/Header';
import Routes from './Routes';

const Router = () => {
  const localizedPaths = usePathsStore(state => state.paths);

  return (
    <BrowserRouter>
      <Header />
      <main id="content" className="site-main">
        <Routes localizedPaths={localizedPaths} />
      </main>
    </BrowserRouter>
  );
};

export default Router;
