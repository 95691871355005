export type AccommodationType = {
  accommodationType?: string;
  numberOfAdults: number;
  numberOfChildren?: number;
  numberOfInfants?: number;
  childrenAges?: number[];
  chichildrenBirthdates?: string[];
};

export const defaultAccommodation: AccommodationType = {
  numberOfAdults: 2,
  numberOfChildren: 0,
  childrenAges: [],
};
