import { useState, useEffect, useRef } from 'react';
import { isValid, addDays, addMonths, differenceInDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import useFetchTranslationsSelective from '../../../../features/translations/hooks/useFetchTranslationsSelective';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarInput from '../CalendarInput/CalendarInput.jsx';

const DatepickerRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startPlaceholderText,
  endPlaceholderText,
  containerClass,
  rowClass,
}) => {
  const [endIsOpen, setEndIsOpen] = useState(false);

  const changeStartDate = date => {
    setStartDate(date);
    if (date > endDate) {
      if (isValid(endDate)) setEndDate(addDays(date, 1));
      setEndIsOpen(true);
    } else if (isValid(date) && isValid(endDate) && differenceInDays(endDate, date) > 15) {
      setEndDate(null);
      setEndIsOpen(true);
    }
  };
  const changeEndDate = date => {
    setEndDate(date);
    setEndIsOpen(false);
  };

  const openDatepicker = () => this._endDateCalendar.setOpen(true);

  const translationWords = ['ArrivalDate', 'ReturnDate'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  return (
    <>
      <div className={containerClass}>
        <div className={rowClass}>
          <label>{translations?.ArrivalDate}</label>
          <DatePicker
            selected={startDate}
            onChange={date => changeStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()}
            placeholderText={startPlaceholderText}
            customInput={<CalendarInput />}
            maxDate={addMonths(new Date(), 13)}
            dateFormat="EEE, d MMM yyyy"
            disabledKeyboardNavigation={true}
            focusSelectedMonth={true}
          />
        </div>
        <div className={rowClass}>
          <label>{translations?.ReturnDate}</label>
          <DatePicker
            selected={endDate}
            onInputClick={() => setEndIsOpen(!endIsOpen)}
            onClickOutside={() => setEndIsOpen(false)}
            onChange={date => changeEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText={endPlaceholderText}
            customInput={<CalendarInput />}
            maxDate={addMonths(new Date(), 13)}
            dateFormat="EEE, d MMM yyyy"
            showMonthYearDropdown
            open={endIsOpen}
            disabledKeyboardNavigation={true}
            focusSelectedMonth={false}
          />
        </div>
      </div>
    </>
  );
};

export default DatepickerRange;
