import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './CalendarInput.scss';

const CalendarInput = forwardRef((props, ref) => {
  return (
    <div className="custominput" style={{ backgroundColor: 'white' }} onClick={props.onClick}>
      <label ref={ref}>{props.value || props.placeholder}</label>
      <span className="icon">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </span>
    </div>
  );
});

export default CalendarInput;
