import { ElementType, lazy, Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router';
import MainLoader from '../components/common/Loaders/MainLoader/MainLoader';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<MainLoader></MainLoader>}>
      <Component {...props} />
    </Suspense>
  );

const Facilities = Loadable(lazy(() => import('../components/pages/FacilitiesPage/FacilitiesPage')));
const Rooms = Loadable(lazy(() => import('../components/pages/RoomsPage/RoomsPage')));
const RoomPage = Loadable(lazy(() => import('../components/pages/RoomPage/RoomPage')));
const Booking = Loadable(lazy(() => import('../components/pages/BookingPage/BookingPage')));
const Gallery = Loadable(lazy(() => import('../components/pages/Gallery/Gallery')));
const LocationPage = Loadable(lazy(() => import('../components/pages/LocationPage/LocationPage')));
const FAQ = Loadable(lazy(() => import('../components/pages/FAQ/FAQ')));
const HomeMemo = Loadable(lazy(() => import('../components/pages/Home/HomeMemo')));
const PricesAndDiscounts = Loadable(lazy(() => import('../components/pages/PricesAndDiscounts/PricesAndDiscounts')));
const Translations = Loadable(lazy(() => import('../components/pages/Translations/Translations')));
const HotelTest = Loadable(lazy(() => import('../components/pages/HotelTest/HotelTest')));
const HotelTest2 = Loadable(lazy(() => import('../components/pages/HotelTest/HotelTest2')));
const Test = Loadable(lazy(() => import('../components/pages/Test/Test')));
const HtmlPage = Loadable(lazy(() => import('../components/pages/HtmlPage/HtmlPage')));
const ErrorPage = Loadable(lazy(() => import('../components/pages/ErrorPage/ErrorPage')));
const TransfersPage = Loadable(lazy(() => import('../components/pages/TransfersPage/TransfersPage')));
const SkiPacksPage = Loadable(lazy(() => import('../components/pages/SkiPacksPage/SkiPacksPage')));
const SearchResultsHotelPage = Loadable(
  lazy(() => import('../components/pages/SearchResultsHotelPage/SearchResultsHotelPage')),
);
const TransferBookingPage = Loadable(lazy(() => import('../components/pages/BookingPage/TransferBookingPage')));
const SkiBookingPage = Loadable(lazy(() => import('../components/pages/BookingPage/SkiBookingPage')));
const HotelPage = Loadable(lazy(() => import('../components/pages/HotelPage/HotelPage')));

/* temp components */
const Dev1 = Loadable(lazy(() => import('../components/pages/DevPages/Dev1')));
const Dev2 = Loadable(lazy(() => import('../components/pages/DevPages/Dev2')));
const Dev3 = Loadable(lazy(() => import('../components/pages/DevPages/Dev3')));
const Dev4 = Loadable(lazy(() => import('../components/pages/DevPages/Dev4')));
const Dev5 = Loadable(lazy(() => import('../components/pages/DevPages/Dev5')));
const Dev6 = Loadable(lazy(() => import('../components/pages/DevPages/Dev6')));

const LazyRoutes = ({ localizedPaths }) => {
  let lazyRoutes: RouteObject[] = [
    {
      path: localizedPaths.home,
      element: <HomeMemo />,
    },
    {
      path: localizedPaths.test,
      element: <Test />,
    },
    {
      path: localizedPaths.dev1,
      element: <Dev1 />,
    },
    {
      path: localizedPaths.hotel,
      element: <HotelPage />,
    },
    {
      path: localizedPaths.facilities,
      element: <Facilities />,
    },
    {
      path: localizedPaths.rooms,
      element: <Rooms />,
    },
    {
      path: localizedPaths.booking,
      element: <Booking />,
    },
    {
      path: localizedPaths.gallery,
      element: <Gallery />,
    },
    {
      path: localizedPaths.location,
      element: <LocationPage />,
    },
    {
      path: localizedPaths.prices,
      element: <PricesAndDiscounts />,
    },
    {
      path: localizedPaths.faq,
      element: <FAQ />,
    },
    {
      path: localizedPaths.translations,
      element: <Translations />,
    },
    {
      path: localizedPaths.hotel1,
      element: <HotelTest />,
    },
    {
      path: localizedPaths.hotel2,
      element: <HotelTest2 />,
    },
    {
      path: localizedPaths.test,
      element: <Test />,
    },
    {
      path: localizedPaths.errorNotFound,
      element: <ErrorPage />,
    },
    {
      path: localizedPaths.errorNoData,
      element: <ErrorPage />,
    },
    {
      path: localizedPaths.room,
      element: <RoomPage />,
    },
    {
      path: localizedPaths.transfers,
      element: <TransfersPage />,
    },
    {
      path: localizedPaths.skipacks,
      element: <SkiPacksPage />,
    },
    {
      path: localizedPaths.searchResults,
      element: <SearchResultsHotelPage />,
    },
    {
      path: localizedPaths.transferBooking,
      element: <TransferBookingPage />,
    },
    {
      path: localizedPaths.skipacksBooking,
      element: <SkiBookingPage />,
    },
    {
      path: localizedPaths.htmlPage,
      element: <HtmlPage />,
    },
    {
      path: localizedPaths.htmlPageWithLang,
      element: <HtmlPage />,
    },
    {
      path: localizedPaths.htmlPageWithLangFiledir,
      element: <HtmlPage />,
    },
    {
      path: localizedPaths.htmlPageWithLangFiledirParentdir,
      element: <HtmlPage />,
    },
    {
      path: localizedPaths.dev1,
      element: <Dev1 />,
    },
    {
      path: localizedPaths.dev2,
      element: <Dev2 />,
    },
    {
      path: localizedPaths.dev3,
      element: <Dev3 />,
    },
    {
      path: localizedPaths.dev4,
      element: <Dev4 />,
    },
    {
      path: localizedPaths.dev5,
      element: <Dev5 />,
    },
    {
      path: localizedPaths.dev6,
      element: <Dev6 />,
    },
  ];

  const contents = useRoutes(lazyRoutes);
  return <>{contents}</>;
};

export default LazyRoutes;
