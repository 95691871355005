import { useQuery } from 'react-query';
import { endPoints, reactQuerySlowChangingConfig } from '../../../api/api.config';
import { getAllApiLang } from '../../../api/api.calls';
import { TranslationModel } from '../translation';
import { useLanguageStore } from '../../../store/languageStore/useLanguageStore';

/*This function won't send an http request if not necessary.
 * So we can use this function to sync states in different components
 * */
export default function useFetchTranslations() {
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);

  return useQuery(
    'translations-' + activeLanguage,
    () => GetAllTranslationsObject(activeLanguage),
    reactQuerySlowChangingConfig,
  );
}

/**
 * Helper function that transforms translations from array of translation objects
 * into associative array with ltitle as index and lword as value
 * @returns something
 */
async function GetAllTranslationsObject(language) {
  const promise = getAllApiLang<TranslationModel>(endPoints.translations, language);

  const processedDataPromise = promise.then(response =>
    response.data.reduce<Record<string, any>>(
      (a, v) =>
        v.lang === language ? { ...a, [v.id]: v.caption } : { ...a, [v.lang]: { ...a[v.lang], [v.id]: v.caption } },
      {} as Record<string, string>,
    ),
  );

  return processedDataPromise;
}
