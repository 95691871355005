import { AccommodationType, defaultAccommodation } from './accommodationType';
import { addDays } from 'date-fns';
export type HotelSearchType = {
  fromDate: string;
  toDate: string;
  accommodationNumber: number;
  accommodationData: AccommodationType[];
  hotelId?: string;
  hotelName?: string;
  xHotelId?: string;
  xHotelName?: string;
  stars?: number;
  starsOptions?: number[];
  boardBasis?: number;
  boardBasisOptions?: number[];
  locationId?: string;
  locationName?: string;
  isDefault?: boolean;
};

export const defaultHotelSearch: HotelSearchType = {
  fromDate: addDays(new Date(), 67).toISOString(),
  toDate: addDays(new Date(), 74).toISOString(),
  accommodationNumber: 1,
  accommodationData: [defaultAccommodation],
  isDefault: true,
};
