import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useHotelSearchStore } from '../../../store/hotelBookingStore/useHotelSearchStore';
import { usePathsStore } from '../../../store/pathsStore/usePathsStore';
import useFetchTranslationsSelective from '../../../features/translations/hooks/useFetchTranslationsSelective';
import useFetchHotelSelective from '../../../features/hotels/hooks/useFetchHotelSelective';
import 'react-datepicker/dist/react-datepicker.css';
import DatepickerRange from '../common/DatepickerRange/DatepickerRange';
import AccommodationPax from '../partials/AccommodationPax/AccommodationPax';
import SearchFormLayoutSide from './SearchFormLayoutSide';
import SearchFormLayoutHorizontal from './SearchFormLayoutHorizontal';

const SearchForm = ({ skipTitle, onSearchFormSubmit, useHorizontalLayout = false }) => {
  const navigate = useNavigate();
  const localizedPaths = usePathsStore(state => state.paths);

  const hotelSearch = useHotelSearchStore(state => state.hotelSearch);
  const setHotelSearch = useHotelSearchStore(state => state.setHotelSearch);
  const setHotelId = useHotelSearchStore(state => state.setHotelId);
  const setHotelSearchFromDate = useHotelSearchStore(state => state.setFromDate);
  const setHotelSearchToDate = useHotelSearchStore(state => state.setToDate);
  const setHotelSearchAccommodationNumber = useHotelSearchStore(state => state.setAccommodationNumber);
  const setNumberOfAdults = useHotelSearchStore(state => state.setNumberOfAdults);
  const setNumberOfChildren = useHotelSearchStore(state => state.setNumberOfChildren);
  const setChildAge = useHotelSearchStore(state => state.setChildAge);

  const translationWords = [
    'VarArrival',
    'VarDeparture',
    'FormRoomType',
    'VarAdults',
    'VarChildren',
    'VarMeals',
    'VarSearch',
    'VarRoom',
    'VarAdvancedSearch',
    'VarCheckAvailabilityPrice',
    'VarAddRoom',
    'ArrivalDate',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = ['id'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);

  useEffect(() => {
    if (hotel?.id) {
      setHotelId(hotel?.id);
    }
  }, [hotelStatus]);

  const addAccommodation = e => {
    e.preventDefault();
    setHotelSearchAccommodationNumber(hotelSearch.accommodationNumber + 1);
  };

  const removeAccommodation = () => {
    if (hotelSearch.accommodationNumber > 1) setHotelSearchAccommodationNumber(hotelSearch.accommodationNumber - 1);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    if (onSearchFormSubmit) {
      onSearchFormSubmit();
    }
    navigate(localizedPaths.searchResults);
  };

  console.log('hotelSearch: ', hotelSearch);

  if (translationsStatus !== 'success') return <></>;

  const children = {
    datePickerRange: (
      <DatepickerRange
        startDate={new Date(hotelSearch.fromDate)}
        setStartDate={date => setHotelSearchFromDate(date)}
        startPlaceholderText={translations?.VarArrival}
        endDate={new Date(hotelSearch.toDate)}
        setEndDate={date => setHotelSearchToDate(date)}
        endPlaceholderText={translations?.VarDeparture}
        containerClass={useHorizontalLayout ? 'col-lg-6 col-md-12  arrival-departure' : 'col-md-12'}
        rowClass={useHorizontalLayout ? 'form-outer' : 'form-group'}
      />
    ),
    accommodationsList:
      hotelSearch?.accommodationNumber > 0
        ? [...Array(hotelSearch.accommodationNumber)].map((x, i) => (
            <AccommodationPax
              key={i}
              index={i}
              accommodationData={hotelSearch.accommodationData[i]}
              isLast={i === hotelSearch.accommodationNumber - 1 ? true : false}
              removeAccommodation={removeAccommodation}
              setNumberOfAdults={setNumberOfAdults}
              setNumberOfChildren={setNumberOfChildren}
              setChildAge={setChildAge}
            />
          ))
        : '',
    addAccommodationsLink:
      hotelSearch.accommodationNumber < 4 ? (
        <a href="#" className="add-room" onClick={e => addAccommodation(e)}>
          add room +
        </a>
      ) : (
        ''
      ),
    formTitle: skipTitle ? '' : translations?.VarCheckAvailabilityPrice,
    buttonText: translations?.VarSearch,
    onFormSubmit,
  };

  console.log('useHorizontalLayout: ', useHorizontalLayout);
  return translationsStatus === 'loading' ? (
    ''
  ) : useHorizontalLayout === true ? (
    <SearchFormLayoutHorizontal {...children} />
  ) : (
    <SearchFormLayoutSide {...children} />
  );
};

export default SearchForm;
