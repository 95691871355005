import './SearchFormLayoutHorizontal.scss';

const SearchFormLayoutHorizontal = ({
  datePickerRange,
  accommodationsList,
  addAccommodationsLink,
  buttonText,
  onFormSubmit,
}) => {
  return (
    <div className="col-12" style={{ padding: '0' }}>
      <form className="booking-form" onSubmit={onFormSubmit}>
        <div className="horizontal-booking-form">
          <div className="row">
            {datePickerRange}
            <div className=" col-lg-4 col-md-12 form-rooms">
              {accommodationsList}
              {addAccommodationsLink}
            </div>

            <div className="col-mg-12 col-lg-2 booknow-container">
              <input type="submit" name="submit" className="book-now-button" value={buttonText} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchFormLayoutHorizontal;
