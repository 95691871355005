import create from 'zustand';
import produce, { Draft } from 'immer';
import { persist } from '../persist';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { HotelSearchType, defaultHotelSearch } from './hotelSearchType';

type hotelSearchStoreType = {
  hotelSearch: HotelSearchType;
  setHotelSearch: (search: HotelSearchType) => void;
  setHotelId: (hotelId: string) => void;
  setFromDate: (fromDate: Date) => void;
  setToDate: (toDate: Date) => void;
  setAccommodationNumber: (accommodationNumber: number) => void;
  setNumberOfAdults: (numberOfAdults: number, accommodationIndex: number) => void;
  setNumberOfChildren: (numberOfChildren: number, accommodationIndex: number) => void;
  setChildAge: (childAge: number, accommodationIndex: number, childIndex: number) => void;
};

export const useHotelSearchStore = create<hotelSearchStoreType>(
  persist(
    {
      key: 'hotelSearchStore',
    },
    (set): hotelSearchStoreType => ({
      hotelSearch: defaultHotelSearch,
      setHotelSearch: (search: HotelSearchType) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch = search;
          }),
        ),
      setHotelId: (hotelId: string) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.hotelId = hotelId;
          }),
        ),
      setFromDate: (fromDate: Date) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.fromDate = fromDate.toISOString();
            draft.hotelSearch.isDefault = false;
          }),
        ),
      setToDate: (toDate: Date) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.toDate = toDate.toISOString();
            draft.hotelSearch.isDefault = false;
          }),
        ),
      setAccommodationNumber: (accommodationNumber: number) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.accommodationNumber = accommodationNumber;
            draft.hotelSearch.isDefault = false;
          }),
        ),
      setNumberOfAdults: (numberOfAdults: number, accommodationIndex: number) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.accommodationData[accommodationIndex] = draft?.hotelSearch?.accommodationData[
              accommodationIndex
            ]
              ? { ...draft.hotelSearch.accommodationData[accommodationIndex], numberOfAdults }
              : { numberOfAdults };
            draft.hotelSearch.isDefault = false;
          }),
        ),
      setNumberOfChildren: (numberOfChildren: number, accommodationIndex: number) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.accommodationData[accommodationIndex] = draft?.hotelSearch?.accommodationData[
              accommodationIndex
            ]
              ? {
                  ...draft.hotelSearch.accommodationData[accommodationIndex],
                  numberOfChildren,
                  childrenAges: draft.hotelSearch.accommodationData[accommodationIndex].childrenAges
                    ? draft.hotelSearch.accommodationData[accommodationIndex].childrenAges.slice(0, numberOfChildren)
                    : [],
                }
              : {
                  numberOfAdults: 2,
                  numberOfChildren,
                  childrenAges: [],
                };
            draft.hotelSearch.isDefault = false;
          }),
        ),
      setChildAge: (childAge: number, accommodationIndex: number, childIndex: number) =>
        set(
          produce((draft: Draft<hotelSearchStoreType>) => {
            draft.hotelSearch.accommodationData[accommodationIndex] = {
              ...(draft?.hotelSearch?.accommodationData[accommodationIndex]
                ? {
                    ...draft.hotelSearch.accommodationData[accommodationIndex],
                    childrenAges:
                      draft?.hotelSearch?.accommodationData[accommodationIndex].childrenAges &&
                      draft?.hotelSearch?.accommodationData[accommodationIndex].childrenAges[childIndex]
                        ? draft?.hotelSearch?.accommodationData[accommodationIndex].childrenAges
                            .slice(0, draft.hotelSearch.accommodationData[accommodationIndex].numberOfChildren)
                            .map((item, index) => (index === childIndex ? childAge : item))
                        : [...draft?.hotelSearch?.accommodationData[accommodationIndex].childrenAges, childAge],
                  }
                : { numberOfAdults: 1, numberOfChildren: 2 }),
            };
            draft.hotelSearch.isDefault = false;
          }),
        ),
    }),
  ),
);

mountStoreDevtool('hotelSearchStore', useHotelSearchStore as any);
